import classNames from 'classnames'
import React from 'react'
import { fadeInDown, fadeOutDown } from 'react-animations'

import Img from 'gatsby-image'
import Radium, { StyleRoot } from 'radium'

import styles from './styles.module.css'

const CurrentAvatar = ({ member }, loading) => {
  const animationStyles = {
    fadeInDown: {
      animation: 'x 1s',
      animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
    },
    fadeOutDown: {
      animation: 'x 1s',
      animationName: Radium.keyframes(fadeOutDown, 'fadeOutDown')
    },
  }
  
  return (
    <StyleRoot>
      <div
        style={animationStyles.fadeInDown}
        key={Math.random()}
        className={classNames({
          [styles.avatarContainer]: true,
        })}
      >
          {member.avatar.value.childImageSharp?
            <Img
              className={styles.avatar}
              fluid={member.avatar.value.childImageSharp.fluid}
              onStartLoad={loading.register}
              onLoad={loading.notify}
            />:
            <img 
                src={member.avatar.value.publicURL}
                className={styles.gif}
                alt="Loading"
            />
          }
        </div>
    </StyleRoot>
  )
}



export default CurrentAvatar


