import classNames from 'classnames';
import CurrentAvatar from './CurrentAvatar';
import React, { Component } from 'react';
import eventEmitter from '../../eventEmitter';
import { isMobile } from '../../shared';
import TrayItem from './TrayItem';

import styles from './styles.module.css';

class TeamMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: undefined,
			hovered: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: false,
			},
		};
	}

	componentDidMount() {
		eventEmitter.on('openTray', id => {
			this.setState({ opened: undefined });
		});

		eventEmitter.on('closeTray', id => {
			this.setState({ opened: undefined });
		});

		eventEmitter.on('openItem', id => {
			this.setState({ opened: id });
		});

		this.setState({ isMobile: isMobile() });
		window.addEventListener('resize', this.updateViewport);
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateViewport);
		eventEmitter.off();
	};

	updateViewport = () => {
		const mobile = isMobile();
		if (this.state.isMobile !== mobile) {
			this.setState({ isMobile: mobile });
		}
		if (!mobile && this.state.fullscreen) {
			this.setState({ fullscreen: false });
		}
	};

	render() {
		const {
			teamMembers: { value },
			loading,
		} = this.props;

		const isExpanded = number => {
			return this.state.opened === number;
		};

		const isSmall = number => {
			return this.state.opened ? this.state.opened !== number : false;
		};

		const isOffscreen = number => {
			return (
				this.state.opened &&
				!(
					this.state.opened - 1 === number ||
					this.state.opened + 1 === number ||
					this.state.opened === number
				)
			);
		};

		return (
			<div
				className={classNames({
					[styles.tray]: true,
				})}
				data-aos="fade-down"
				data-aos-duration="1000"
			>
				<div className={styles.left}>
					{this.state.opened !== undefined && (
						<CurrentAvatar
							member={value[this.state.opened - 1]}
							loading={loading}
						/>
					)}
					{this.state.opened === undefined && (
						<CurrentAvatar member={value[3]} loading={loading} />
					)}
				</div>

				<div className={classNames(styles.items, styles.right)}>
					{value.map((teamMember, i) => {
						const index = i + 1;

						return (
							<TrayItem
								id={index}
								offscreen={isOffscreen(index)}
								color={i % 2 === 0 ? 'blue' : 'lightblue'}
								expanded={isExpanded(index)}
								small={isSmall(index)}
								handleTrays={this.handleTrays}
								member={value[i]}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	handleTrays = trayId => {
		this.setState({
			opened: this.state.opened === trayId ? undefined : trayId,
		});
	};
}

export default TeamMembers;
