import AOS from 'aos'
import { graphql } from 'gatsby'
import React, { PureComponent } from 'react'

import Layout from '../components/Layout'
import TeamMembers from '../components/TeamMembers'
import withLoading from '../hocs/withLoading'

import styles from './styles-team.module.css'

class TeamPage extends PureComponent {

  componentDidMount = () => {
    AOS.init({
      duration: 700,
      offset: 120,
    })
  }

  render() {
    const { pageContext, data, loading } = this.props
    const { cockpitTeam: { teamMembers } } = data


    return (
      <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
        <div className={styles.background}>
          <TeamMembers teamMembers={teamMembers} loading={loading} />
        </div>
      </Layout>
    )
  }
}

export default withLoading(TeamPage)

export const query = graphql`
{
  cockpitTeam(
    cockpitId: { eq: "5d39e60874d1a200a3283904"}
  ) {
    teamMembers {
      value {
        title {
          value
        }
        description {
          value {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
        name {
          value
        }
        avatar {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
            publicURL
          }
        }
      }
    }
  }
}
`
