import BoostedWord from '../../BoostedWord';
import classNames from 'classnames'
import React, { Component } from 'react'
import { fadeInUp } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import RehypeReact from 'rehype-react'

import styles from './styles.module.css'
import Email from '../../Email';

class CurrentMember extends Component {
  render() {
    const { member } = this.props
    const animationStyles = {
      fadeInUp: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
      },
    }
    return (
      <div
        className={classNames({
          [styles.currentMember]: true,
        })}
        key={Math.random()}
      >
        <StyleRoot>
          <div
            style={animationStyles.fadeInUp}
            className={classNames({
              [styles.content]: true,
            })}
          >
            <div
              className={classNames({
                [styles.title]: true,
              })}
            >
              <div>
                {member.title.value}
              </div>
            </div>
            <div className={styles.line} />
            <div
              className={styles.descriptionDiv}
            >
              {new RehypeReact({
                createElement: React.createElement,
                components: {
                boosted: BoostedWord(styles.name),
                email: Email(styles.email),
                },
                }).Compiler(member.description.value.childMarkdownRemark.htmlAst)
              }

            </div>
            <div className={styles.line} />
          </div>
        </StyleRoot>
      </div>
    )
  }
}

export default CurrentMember

