import classNames from 'classnames';
import CurrentMember from '../CurrentMember';
import React, { Component } from 'react';

import CollapseArrow from '../../CollapseArrow';

import styles from './styles.module.css';

class TrayItem extends Component {
	render() {
		return (
			<div
				onClick={() => this.props.handleTrays(this.props.id)}
				className={classNames({
					[styles.trayItem]: true,
					[styles.blue]: this.props.color === 'blue',
					[styles.lightblue]: this.props.color === 'lightblue',
					[styles.expandedBig]:
						this.props.expanded &&
						(this.props.id === 1 || this.props.id === 4),
					[styles.expandedSmall]: this.props.expanded,
					[styles.small]: this.props.small,
					[styles.offscreen]: this.props.offscreen,
				})}
			>
				<div
					className={classNames({
						[styles.trayItem__header]: true,
						[styles.blue]: this.props.color === 'blue',
						[styles.lightblue]: this.props.color === 'lightblue',
					})}
				>
					<div
						className={classNames({
							[styles.buttonDiv]: true,
							[styles.expandedButton]: this.props.expanded,
						})}
					>
						{!this.props.small ? (
							<CollapseArrow isCollapsed={this.props.expanded} />
						) : (
							<div className={styles.emptyCollapseArrow} />
						)}
					</div>
					<div
						className={classNames({
							[styles.title]: true,
							[styles.hidden]: this.props.small,
							[styles.expandedTitle]: this.props.expanded,
						})}
					>
						<div className={styles.titleText}>
							{this.props.member.name.value}
						</div>
						<div className={styles.subtitleText}>
							{this.props.member.title.value}
						</div>
					</div>
				</div>
				<div
					className={classNames({
						[styles.currentMember]: true,
						[styles.hidden]: !this.props.expanded,
					})}
				>
					<CurrentMember member={this.props.member} />
				</div>
			</div>
		);
	}
}

export default TrayItem;
